import React from 'react'

import Layout from '../components/Layout'
import ConnectHero from '../components/ConnectHero'

const BoostPage = () => (
    <Layout
        bodyClass="connect"
        title="Mobile Apps for Real Estate Search | Connect by HomeSpotter"
        description="Tools to centralize your conversations, collaborate easily, and share real-time information around the clock"
    >
        <ConnectHero />
    </Layout>
)

export default BoostPage
