import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import WhoYouAreButtons from './WhoYouAreButtons'

const ConnectHero = ({ data }) => {
    return (
        <div className="hero col-12">
            <div className="copy-content text-white span-7-mx">
                <h1>Cultivate your most important relationships</h1>
                <p>
                    Our modern platform gives agents, brokers, and MLSs the
                    technology and tools to make buying and selling homes a
                    delightful experience for clients.
                </p>
                <div className="call-to-action">I need a solution for:</div>
                <WhoYouAreButtons
                    path="connect"
                    additionalOpts={[{ route: 'mls', display: 'MLSs' }]}
                />
            </div>
            <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                className="hero-illustration solution"
            />
        </div>
    )
}

const ConnectHeroRender = props => (
    <StaticQuery
        query={graphql`
            {
                file(relativePath: { eq: "connectSolutionHero.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 502
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        `}
        render={data => <ConnectHero data={data} {...props} />}
    />
)

export default ConnectHeroRender
